.image-logo-about {
    display: none;
}

@media screen and (min-width: 992px) {
    .image-logo-about {
        display: block;
    }
    .image-logo-about div {
        height: 300px;
    }
}